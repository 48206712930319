import React from "react";
import Layout from "../components/Layout";
import { Helmet } from 'react-helmet'
import { Link } from "exo";

const aboutus = () => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Auto Genius - Code of Conduct</title>
        <meta
          name="description"
          content="The code that protects the Auto Genius community of automotive marketing professionals."
        />
      </Helmet>
      <Layout>
      
      <div className="max-w-5xl pt-36 md:pt-48 px-10 md:px-2 mx-auto">
        <h1 className="billrightTitle text-center text-3xl md:text-5xl font-bold mb-20">
          Code of Conduct
        </h1>
        <div className="text-black text-md b-10 max-w-4xl mx-auto terms">
        <p>Last Updated on August 15, 2021.</p>
        <p>This Code of Conduct applies to your participation and involvement in any Genius Community (such as Auto Genius) operated by the Company or other use of the Company’s Services that involves other Geniuses or participants, including without limitation your participation in any Event or any of your Genius Contributions. This Code of Conduct applies whether you are a Genius or other user of the Services or any part thereof.</p>
<p>All capitalized terms not defined in this Code of Conduct shall have the meanings set forth for such terms in the Company’s <Link to="/terms-of-service">Terms of Service</Link>.</p>
<p><strong>Your Participation in Events, Interactive Features, and Other Company-Related Activities</strong></p>
<p>The Company is committed to creating a healthy, exciting, respectful, supportive Genius Community and environment for all Geniuses and other participants participating in any Company-related activities, including at or through any Event or otherwise on or through the Platform.</p>
<p>With that goal the Company asks each Genius and other participant to adhere to the following guidelines when attending or participating at any Event, when using the Platform or when engaged in any other communications with other Geniuses or other users of the Services in the context of any Company-related activities:</p>
<ol >
<li><strong>Participate Actively and Thoughtfully: </strong>You are expected to participate in and contribute to all Events and other Company-related activities you take part in authentically and actively. In doing so, you contribute to the health and longevity of the Company’s Communities.</li>
<li><strong>Help Your Fellow Geniuses When You are Able to Do So: </strong>You are expected to be responsive and helpful to the fellow Geniuses of your Genius Community. For instance, when there is another Genius of the same Genius Community traveling to your city, another Genius of the same Genius Community asking for help or who just wants to meet for coffee, we ask that you respond promptly (whether you can or cannot be of assistance) and to offer reasonable assistance if your schedule permits.</li>
<li><strong>Be Considerate and Respectful: </strong>You are expected to exercise consideration and respect in your speech and actions.</li>
<li><strong>No Demeaning, Discriminatory or Harassing Speech or Actions: </strong>You are expected to refrain from demeaning, discriminatory, or harassing behavior and speech. This includes without limitation harmful or prejudicial verbal or written comments related to gender, sexual orientation, race, religion, disability; inappropriate use of nudity and/or sexual images (including presentation slides); inappropriate depictions of violence (including presentation slides); deliberate intimidation, stalking or following; harassing photography or recording; sustained disruption of an Event or other Company-related activities; inappropriate physical contact, and unwelcome sexual attention. Event venues may be shared with Geniuses of the public, so please be respectful to all other persons at these locations.</li>
<li><strong>Privacy and Confidentiality: </strong>Many Events and other interactions between Geniuses and others through the Platform or otherwise in the context of Company-related activities are intended to be private and confidential between the participants. As such, you must agree and adhere to each of the following privacy and confidentiality guidelines:</li>
</ol>
<p>• Any information you learn about another Genius through the Company, whether at, from or through an Event, the Platform or otherwise, must be kept private and confidential and only shared with other Geniuses of the same Genius Community and (if applicable) only those other participants in the same Event or other activity at which that information was disclosed unless the Genius that such information concerns expressly permits wider disclosure.<br/>
• Likewise, that information cannot be used for any purpose other than for internal Company purposes (for example, discussions with others Geniuses, to provide advice to another Genius, etc.) unless the Genius that such information concerns expressly permits otherwise.<br/>
• If you learn something about a Genius in a small group setting (for example, an Event like a dinner) or in a one-on-one setting (such as a coaching session), you will not share that information with anyone not present, not even other Geniuses, unless the Genius that such information concerns expressly permits otherwise.</p>
<ol  start="6">
<li><strong>Safety and Mindfulness! </strong>You are expected to be mindful of your surroundings and of your fellow Geniuses and other participants in Event and other Company-related activities. <strong>Notwithstanding our guidelines on privacy and confidentiality (see #5 above), you are required to alert Company management and/or Event or other activity leaders or organizers (for example, the head of your Genius Community or such other Event or activity leaders or organizers as may be present) if you notice a dangerous situation, someone in distress, or someone engaging in demeaning, discriminatory or harassing behavior towards another Genius or other individual, even if that seems inconsequential to you.</strong></li>
<li><strong>Legal Compliance: </strong>Your Genius Contributions must in their entirety comply with all Applicable Law.</li>
<li><strong>Your Contributions as a Genius: </strong>Without limiting anything in the Terms of Service that may also apply, Genius Contributions must not:</li>
</ol>
<p>a. Contain any material or content that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.<br/>
b. Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.<br/>
c. Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.<br/>
d. Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with the <Link to="/terms-of-service">Terms</Link> (including this <Link to="/code-of-conduct">Code of Conduct</Link> and our <Link to="/privacy-policy">Privacy Policy</Link>).<br/>
e. Be likely to deceive any person.<br/>
f. Promote any illegal activity, or advocate, promote, or assist any unlawful act.<br/>
g. Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.<br/>
h. Impersonate any person or misrepresent your identity or affiliation with any person or organization.<br/>
i. Involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising.<br/>
j. Give the impression that they emanate from or are endorsed by Company or any other person or entity if this is not the case.<br/>
k. Actively solicit business or promote a product or service that has not been explicitly requested by a Member. If you benefit financially in some way from suggestions you share, you must disclose the nature of your relationship.</p>
<p><strong>Consequences of Violating the Code of Conduct</strong></p>
<p>Violations of this Code of Conduct by any Genius or other participant or user of the Services will not be tolerated. Anyone asked to stop the applicable conduct is expected to comply immediately.</p>
<p>If any Genius or other participant or user of the Services violates the Code of Conduct, the Company or others acting at their direction may take any action they deem appropriate, up to and including ejecting someone from an Event, temporarily suspending or permanently terminating an individual’s Membership or right to access or use some or all of the Services. No refund of any Membership Fees or other fees or charges would be provided in any such event.</p>
<p><strong>If You Witness or Are Subject to Unacceptable Behavior</strong></p>
<p>If you are subject to or witness a violation of the Code of Conduct or have any other concerns, please notify the Company as soon as possible at success@autogenius.io. In the context of any in-person Events, the Company will also provide escorts as desired by a person experiencing distress.</p>
<p><strong>Addressing Grievances</strong></p>
<p>If you feel you have been falsely or unfairly accused of violating this Code of Conduct, you should notify the Company at success@autogenius.io with a concise description of your grievance. Your grievance will be handled in accordance with our existing governing policies.</p>
<p><strong>License and attribution</strong></p>
<p>This Code of Conduct is based on part on the Berlin Code of Conduct, which is distributed under a <a target="new" href="https://creativecommons.org/licenses/by-sa/4.0/" rel="nofollow">Creative Commons Attribution-ShareAlike 4.0 International (CC BY-SA 4.0)</a>&nbsp;license.</p>

      </div> </div>
      <hr size="1" className="mt-5 mb-5"/>
    </Layout></>
  );
};

export default aboutus;
